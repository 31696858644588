<template>
  <el-card shadow="hover" header="" class="x-card-title">
    <div class="im">
      <img :src="person.face || '/img/default.jpg'" alt=""/>
      <div class="motai" v-show="bool">
        <span style="font-weight:600">下载中...</span>
      </div>
    </div>
    <div class="up">
      <el-upload
        class="upload-demo"
        key="shop_logo"
        :action="`${MixinUploadApi}?scene=shop`"
        :on-success="uploadSuccessLogo"
        :before-upload="handleImagesUrlBefore"
        :file-list="fileList_logo"
        ref="fileList_logo"
        list-type="picture"
      >
        <el-button size="small" type="primary">
          上传头像
          <i class="el-icon-upload el-icon--right"></i>
        </el-button>
      </el-upload>
    </div>
    <div class="pp">
      <div class="pp1">
        <span>昵称:</span>
        <div style="display:inline-block">
          <el-input placeholder="请输入昵称" v-model="person.uname" clearable></el-input>
        </div>
      </div>
      <div class="pp1">
        <span>性别:</span>
        <div style="display:inline-block">
          <el-select v-model="person.sex" clearable placeholder="请选择" style="width: 300px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="pp1">
        <span>生日:</span>
        <div style="display:inline-block">
          <el-date-picker v-model="person.birthday" type="date" placeholder="选择日期"></el-date-picker>
        </div>
      </div>
      <div class="pp1">
        <span></span>
        <div style="display:inline-block">
          <el-button type="primary" size="small" @click="save" style="width: 300px">保存</el-button>
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>
import {mapGetters} from 'vuex';
import * as shopApi from '@/api/shopSetting';
import {Foundation} from '@/../ui-utils';

export default {
  name: 'person',
  data() {
    return {
      options: [{
        value: 1,
        label: '男'
      }, {
        value: 0,
        label: '女'
      }],
      name: '',
      person: {
        face: '/img/default.jpg',
        uname: '',
        sex: 1,
        birthday: '1990-01-01'
      },
      fileList_logo: [],
      bool: false
    };
  },
  mounted() {
    this.getInitInfo();
  },
  created() {
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    //图片上传成功后的回调
    uploadSuccessLogo(response) {
      this.person.face = response.url;
      this.bool = false;
    },
    /*图片上传前的验证*/
    handleImagesUrlBefore() {
      this.bool = true;
    },
    /*获得个人基本信息*/
    getInitInfo() {
      shopApi.getUserInfo().then(response => {
        response.birthday = Foundation.unixToDate(response.birthday, 'yyyy-MM-dd');
        this.person = response;
        this.person.uname = response.nickname;
      });
    },
    save: function () {
      let unix = parseInt(new Date(this.person.birthday).getTime() / 1000);

      let params = {
        nickname: this.person.uname,
        sex: this.person.sex,
        birthday: unix,
        shop_member_com_name: this.person.shop_member_com_name,
        shop_member_part_name: this.person.shop_member_part_name,
        face: this.person.face
      };

      shopApi.saveUserInfo(params).then(response => {
        this.$message.success('保存设置成功');
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.zz {
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}

.motai {
  background-color: rgba(163, 153, 153, 0.4);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  text-align: center;
  line-height: 100px;
}

.shu {
  height: 30px;
  width: 6px;
  background-color: rgb(26, 67, 169);
  display: inline-block;
}

.he {
  padding-top: 30px;
  padding-bottom: 30px;
}

.save {
  width: 110px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: auto;
  background-color: rgb(26, 67, 169);
  color: white;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.el-date-editor.el-input {
  width: 100%;
}

.pp1 > span {
  display: inline-block;
  width: 12%;
}

.pp1 > div {
  width: 86%;
}

.pp1 {
  margin-bottom: 10px;
}

.pp {
  width: 350px;
  margin: auto;
}

.up {
  text-align: center;
  line-height: 40px;
  color: rgb(26, 67, 169);
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.con1 {
  background-color: white;
  border-radius: 10px;
  padding-bottom: 30px;
}

.content {
  width: 100%;
  height: 100%;
  background-color: rgb(249, 249, 249);
}

.im {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin: auto;
  position: relative;
}

img {
  border-radius: inherit;
  width: 100%;
  height: 100%;
}

/deep/ .el-upload-list {
  display: none;
}
</style>
